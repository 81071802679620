<template>
    <div style="padding-top: 20px;">
        <div class="addr-header">
            <img class="back-arrow" src="/images/Exchange/Arrowhead_Left.png" @click="exit"/>
            <div class="addr-title">添加收货地址</div>
        </div>
        <div class="addr-form">
            <div class="addr-item">
                <div class="addr-item-title">收货人</div>
                <input v-model="addrData.name" class="addr-item-input" type="text" placeholder="请输入收货人姓名" />
            </div>
            <div class="addr-item">
                <div class="addr-item-title">联系电话</div>
                <input v-model="addrData.phone" class="addr-item-input" type="text" placeholder="请输入收货人手机号码" />
            </div>
            <div class="addr-item horizontal">
                <div class="addr-item-title">选择城市</div>
                <div class="addr-item-hint" @click="openArea">
                    {{ addrData.province.length ? addrData.province + ' ' + addrData.city + ' ' + addrData.region : '请选择收货地区' }}
                    <img class="addr-item-arrow" src="/images/Exchange/Arrowhead_Right.png" />
                </div>
            </div>
            <div class="addr-item" style="padding: 10px 0 20px 0; border-bottom: 0.5px solid #747070;">
                <div class="addr-item-title" style="margin-bottom: 20px;">详细地址</div>
                <textarea v-model="addrData.detail" class="addr-item-textarea" placeholder="完善楼号/单元/门牌号" />
            </div>
            <div class="addr-item horizontal" style="border: none;">
                <div class="addr-item-title">默认地址</div>
                <div class="addr-item-hint">
                    <van-switch v-model="addrData.isDefault" size="18px" active-color="#6149df"/>
                </div>
            </div>

            <div class="addr-save-btn" @click="submitAddr">
                保存
            </div>
            <van-popup v-model="areaShow" position="bottom">
                <van-area title="选择收货地区" :area-list="areaList" @confirm="selectArea" @cancel="closeArea"/>
            </van-popup>
            <van-overlay :show="overlayShow" @click="overlayShow = false">
                <div class="success-hint-box" @click.stop>
                    <img class="success-hint-icon" src="/images/Exchange/Success_Add.png" />
                    <div class="success-hint-text">保存成功</div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    import { areaList } from '@vant/area-data';
    import { Popup, Area, Switch, Overlay, Notify } from 'vant';
    import { addUserAddr } from "@/api/Exchange";

    export default {
        name: "AddAddr",
        data() {
            return {
                areaShow: false,
                areaList: areaList,
                overlayShow: false,
                addrData: {
                    name: '',
                    phone: '',
                    province: '',
                    city: '',
                    region: '',
                    detail: '',
                    isDefault: false
                }
            };
        },
        components: {
            'van-popup': Popup,
            'van-area': Area,
            'van-switch': Switch,
            'van-overlay': Overlay
        },
        methods: {
          exit(){
            this.$router.go(-1)
          },
            selectArea(area) {
                this.addrData.province = area[0].name;
                this.addrData.city = area[1].name;
                this.addrData.region = area[2].name;
                this.areaShow = false;
            },
            closeArea() {
                this.areaShow = false;
            },
            openArea() {
                this.areaShow = true;
            },
            async submitAddr() {
                // check whether the addrData is valid
                const { name, phone, province, city, region, detail } = this.addrData;
                if (!name || !phone || !province || !city || !region || !detail) {
                    Notify({type: "danger", message:'请完善收货地址信息'});
                    return ;
                }
                
                // check phone number is valid
                if (!(/^1[3456789]\d{9}$/.test(phone))) {
                    Notify({type: "danger", message:'请输入正确的手机号码'});
                    return ;
                }
                console.log(this.addrData);

                try {
                    let data = {
                        addrInfo: this.addrData
                    }
                    let info = this.$store.state.user.info;
                    let {errorCode} = await addUserAddr(info.id, data);
                    
                    if (errorCode == 200) {
                        this.overlayShow = true;
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 2000);
                    } else {
                        Notify({type: "danger", message:'添加用户地址失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'添加用户地址失败'});
                }                
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
    .addr-header {
        text-align: center;
        position: relative;
        margin: 0 auto;
    }

    .back-arrow {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .addr-title {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
    }

    .addr-form {
        width: 85%;
        margin: 20px auto;
    }

    .horizontal {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 0.5px solid #747070;
    }

    .addr-item-hint {
        display: flex;
        align-items: center;
        width: 70%;
        justify-content: right;
        font-size: 12px;
        cursor: pointer;
        color: grey
    }

    .addr-item-title {
        font-size: 12px;
        color: #fff;
        margin: 10px 0;
        width: 30%;
    }
    
    .addr-item {
        width: 100%;
    }

    .addr-item-input {
        padding: 10px 20px;
        background-color: rgba(0,0,0,0.5);
        border-radius: 15px;
        border: 0.5px solid grey;
        color: white;
        box-sizing: border-box;
        width: 100%;
        font-size: 12px;
    }

    .addr-item-textarea {
        padding: 10px 20px;
        background-color: #21242d;
        border-radius: 15px;
        color: white;
        box-sizing: border-box;
        width: 100%;
        height: 100px;
        font-size: 12px;
    }

    input:focus {
        outline: none;
        
    }

    textarea:focus {
        outline: none;
    }

    .addr-item-arrow {
        width: 15px;
        height: 15px;
    }

    .addr-save-btn {
        background: linear-gradient(270deg,#81c4f5,#6149df);
        margin: 20px auto;
        width: 95%;
        border-radius: 20px;
        text-align: center;
        font-size: 13px;
        height: 50px;
        border-radius: 15px;
        line-height: 50px;
        color: white;
        cursor: pointer;
    }

    .success-hint-box {
        width: 25%;
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150px;
        height: 120px;
        border-radius: 20px;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .success-hint-icon {
        width: 40px;
    }

    .success-hint-text {
        font-size: 20px;
        color: white;
        font-weight: bold;
    }
</style>